<template>
  <b-modal
    id="away-page-assignment-modal"
    :title="$t('ENTITLEMENT.DETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex flex-column p-5">
      <IndividualEmployeeAwayAssignmentForm
        @onSubmitClicked="submitForm"
      ></IndividualEmployeeAwayAssignmentForm>
    </div>
  </b-modal>
</template>

<script>
import IndividualEmployeeAwayAssignmentForm from "@/modules/company/components/away/assignment/IndividualEmployeeAwayAssignmentForm";
export default {
  name: "EmployeeIndividualAwayAssignmentModal",
  components: { IndividualEmployeeAwayAssignmentForm },
  props: {
    assignedAway: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    submitForm(form) {
      console.log("Form : ", form);

      this.$store
        .dispatch("submitEmployeeAwayIndividualAssignmentForm", {
          form: {
            id: this.assignedAway.away.company_away.id,
            employee_id: this.assignedAway.employee_id,
            test: "Ayam",
            from_date: form.from_date,
            to_date: form.to_date,
            remark: form.remark,
          },
        })
        .then((res) => {
          console.log(res);
          this.$bvModal.msgBoxOk(
            "Successfully set the individual assignment for teacher."
          );
          this.$emit("formSubmitted", form);
        });
    },
  },
};
</script>

<style scoped></style>
