<template>
  <div>
    <b-form class="form" v-on:submit.prevent="onSubmit">
      <h4 class="font-weight-bolder mb-5"></h4>

      <b-form-group label-cols-lg="2" label="Date">
        <b-form-input disabled :value="date"></b-form-input>
      </b-form-group>
      <!-- begin: remark  -->
      <b-form-group
        label-cols-lg="2"
        label-class="font-weight-bolder"
        label="Reason"
      >
        <b-form-textarea
          id="leave-reason"
          v-model="$v.form.remark.$model"
          :state="validateState('remark')"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end: remark  -->

      <div class="d-flex justify-content-end border-top pt-3">
        <b-button
          variant="primary"
          ref="employee-general-button"
          class="font-size-h6"
          type="submit"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
  name: "IndividualEmployeeAwayAssignmentForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        remark: "",
      },
      date: dayjs().format("D/M/YYYY"),
      isBusy: false,
    };
  },
  validations: {
    form: {
      remark: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Assignment Form: ", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const form = {
        from_date: dayjs().format(),
        to_date: dayjs().format(),
        remark: this.form.remark,
      };

      console.log("Created form: ", form);
      this.$emit("onSubmitClicked", form);
    },
  },
};
</script>

<style scoped></style>
